var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.item.getActionBarItemCss(),
      attrs: {
        type: "button",
        disabled: _vm.item.enabled !== undefined && _vm.item.enabled,
        title: _vm.item.tooltip || _vm.item.title,
        role: _vm.item.ariaRole,
        tabindex: _vm.item.disableTabStop ? -1 : 0,
      },
      on: {
        click: function () {
          _vm.item.action()
        },
        keyup: function (evt) {
          evt.stopPropagation()
        },
      },
    },
    [
      _vm.item.iconName
        ? _c("sv-svg-icon", {
            class: _vm.item.cssClasses.itemIcon,
            attrs: {
              iconName: _vm.item.iconName,
              size: _vm.item.iconSize,
              title: _vm.item.tooltip || _vm.item.title,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.item.hasTitle
        ? _c("span", { class: _vm.item.getActionBarItemTitleCss() }, [
            _vm._v(_vm._s(_vm.item.title)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("sv-popup", { attrs: { model: _vm.item.popupModel } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }