

































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, ListModel, Action, IAction } from "survey-core";
import { BaseVue, attachKey2click } from "../../base";

export * from "./list.vue";

@Component
export class ListItem extends BaseVue {
  @Prop() item: Action;
  @Prop() model: ListModel;
  constructor() {
    super();
  }
  getModel() {
    return this.item;
  }
  get elementId() {
    return (this.item as IAction)?.elementId;
  }
  public keyUp(event: any) {
    attachKey2click(event);
  }
  public click(event: any) {
    this.model.onItemClick(this.item as any);
    event.stopPropagation();
  }
  protected onMounted() {
    this.model.onLastItemRended(<any>this.item);
  }
}

Vue.component("sv-list-item", ListItem);
export default ListItem;
