var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.question.cssClasses.root,
      on: {
        keydown: function ($event) {
          return _vm.question.onKeyDownCore($event)
        },
      },
    },
    [
      _c("label", { class: _vm.question.getItemCss() }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.question.booleanValue,
              expression: "question.booleanValue",
            },
          ],
          class: _vm.question.cssClasses.control,
          attrs: {
            type: "checkbox",
            name: _vm.question.name,
            id: _vm.question.inputId,
            disabled: _vm.question.isInputReadOnly,
            "aria-required": _vm.question.ariaRequired,
            "aria-label": _vm.question.ariaLabel,
            "aria-invalid": _vm.question.ariaInvalid,
            "aria-describedby": _vm.question.ariaDescribedBy,
          },
          domProps: {
            value: _vm.question.booleanValue,
            indeterminate: _vm.question.isIndeterminate,
            checked: Array.isArray(_vm.question.booleanValue)
              ? _vm._i(_vm.question.booleanValue, _vm.question.booleanValue) >
                -1
              : _vm.question.booleanValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.question.booleanValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = _vm.question.booleanValue,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(_vm.question, "booleanValue", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.question,
                      "booleanValue",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.question, "booleanValue", $$c)
              }
            },
          },
        }),
        _c(
          "span",
          {
            class: _vm.question.getLabelCss(false),
            on: {
              click: function ($event) {
                return _vm.onLabelClick($event, false)
              },
            },
          },
          [
            _c("survey-string", {
              attrs: { locString: _vm.question.locLabelFalse },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            class: _vm.question.cssClasses.switch,
            on: {
              click: function ($event) {
                return _vm.onSwitchClick($event)
              },
            },
          },
          [
            _c("span", { class: _vm.question.cssClasses.slider }, [
              _vm.question.cssClasses.sliderText && _vm.question.isDeterminated
                ? _c(
                    "span",
                    { class: _vm.question.cssClasses.sliderText },
                    [
                      _c("survey-string", {
                        attrs: { locString: _vm.question.getCheckedLabel() },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        ),
        _c(
          "span",
          {
            class: _vm.question.getLabelCss(true),
            on: {
              click: function ($event) {
                return _vm.onLabelClick($event, true)
              },
            },
          },
          [
            _c("survey-string", {
              attrs: { locString: _vm.question.locLabelTrue },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }