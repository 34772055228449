var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.model.inputStringRendered,
        expression: "model.inputStringRendered",
      },
    ],
    class: _vm.question.cssClasses.filterStringInput,
    attrs: {
      type: "text",
      autocomplete: "off",
      placeholder: _vm.model.filterStringPlaceholder,
      disabled: _vm.question.isInputReadOnly,
      inputmode: _vm.model.inputMode,
      role: _vm.model.filterStringEnabled ? _vm.question.ariaRole : null,
      "aria-label": _vm.question.placeholder,
      "aria-expanded": _vm.question.ariaExpanded ? "true" : "false",
      "aria-controls": _vm.model.listElementId,
      "aria-activedescendant": _vm.model.ariaActivedescendant,
      id: _vm.question.getInputId(),
      readonly: !_vm.model.searchEnabled ? true : null,
      size: !_vm.model.inputStringRendered ? 1 : null,
    },
    domProps: { value: _vm.model.inputStringRendered },
    on: {
      change: _vm.inputChange,
      keydown: _vm.inputKeyHandler,
      blur: _vm.blur,
      focus: _vm.focus,
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.$set(_vm.model, "inputStringRendered", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }