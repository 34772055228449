var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.row.isNeedRender
    ? _c(
        "div",
        {
          class: !_vm.element.isPanel ? _vm.element.getRootCss() : null,
          attrs: {
            id: _vm.element.id,
            role: _vm.element.ariaRole,
            "aria-required": _vm.element.ariaRequired,
            "aria-invalid": _vm.element.ariaInvalid,
            "aria-labelledby": _vm.element.ariaLabelledBy,
            "data-name": _vm.element.name,
          },
          on: {
            focusin: function ($event) {
              return _vm.element.focusIn()
            },
          },
        },
        [
          !_vm.element.isPanel && _vm.element.showErrorsAboveQuestion
            ? _c("survey-errors", {
                attrs: { element: _vm.element, location: "top" },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.element.isPanel && _vm.element.hasTitleOnLeftTop
            ? _c("survey-element-header", {
                attrs: { element: _vm.element, css: _vm.css },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.element.isPanel || !_vm.element.isCollapsed,
                  expression: "element.isPanel || !element.isCollapsed",
                },
              ],
              class: _vm.getContentClass(_vm.element) || undefined,
              attrs: { role: "presentation" },
            },
            [
              _vm.hasErrorsOnTop
                ? _c("survey-errors", {
                    attrs: { element: _vm.element, location: "top" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.element.isPanel || !_vm.element.isCollapsed
                ? _c(_vm.getComponentName(_vm.element), {
                    tag: "component",
                    attrs: { question: _vm.element, css: _vm.css },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.element.hasComment
                ? _c(
                    "div",
                    { class: _vm.element.getCommentAreaCss() },
                    [
                      _c(
                        "div",
                        [
                          _c("survey-string", {
                            attrs: { locString: _vm.element.locCommentText },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("survey-question-comment", {
                        attrs: {
                          commentClass: _vm.css.comment,
                          question: _vm.element,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasErrorsOnBottom
                ? _c("survey-errors", {
                    attrs: { element: _vm.element, location: "bottom" },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.element.isPanel && _vm.element.isErrorsModeTooltip
                ? _c("survey-errors", {
                    attrs: { element: _vm.element, location: "tooltip" },
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.element.isPanel && _vm.element.hasDescriptionUnderInput
                ? _c(
                    "div",
                    { class: _vm.element.cssClasses.descriptionUnderInput },
                    [
                      _c("survey-string", {
                        attrs: { locString: _vm.element.locDescription },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.element.isPanel && _vm.element.hasTitleOnBottom
            ? _c("survey-element-header", {
                attrs: { element: _vm.element, css: _vm.css },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.element.isPanel && _vm.element.showErrorsBelowQuestion
            ? _c("survey-errors", {
                attrs: { element: _vm.element, location: "bottom" },
              })
            : _vm._e(),
        ],
        1
      )
    : !!_vm.element.skeletonComponentName
    ? _c(_vm.element.skeletonComponentName, {
        tag: "component",
        attrs: { element: _vm.element, css: _vm.css },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }