var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.question.cssClasses.selectWrapper },
    [
      !_vm.question.isReadOnly
        ? _c(
            "div",
            {
              class: _vm.question.getControlClass(),
              attrs: {
                id: _vm.question.inputId,
                disabled: _vm.question.isInputReadOnly,
                tabindex: _vm.model.inputReadOnly ? undefined : 0,
                role: _vm.question.ariaRole,
                "aria-required": _vm.question.ariaRequired,
                "aria-label": _vm.question.ariaLabel,
                "aria-invalid": _vm.question.ariaInvalid,
                "aria-describedby": _vm.question.ariaDescribedBy,
                "aria-expanded": _vm.question.ariaExpanded ? "true" : "false",
                "aria-controls": _vm.model.listElementId,
                "aria-activedescendant": _vm.model.ariaActivedescendant,
                required: _vm.question.isRequired,
              },
              on: { click: _vm.click, keydown: _vm.keyhandler, blur: _vm.blur },
            },
            [
              _vm.model.showHintPrefix
                ? _c("div", { class: _vm.question.cssClasses.hintPrefix }, [
                    _c("span", [_vm._v(_vm._s(_vm.model.hintStringPrefix))]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { class: _vm.question.cssClasses.controlValue },
                [
                  _vm.question.showSelectedItemLocText
                    ? _c("survey-string", {
                        attrs: { locString: _vm.question.selectedItemLocText },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.showHintString
                    ? _c("div", { class: _vm.question.cssClasses.hintSuffix }, [
                        _c("span", { staticStyle: { visibility: "hidden" } }, [
                          _vm._v(_vm._s(_vm.model.inputStringRendered)),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.model.hintStringSuffix)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.question.showInputFieldComponent
                    ? _c(_vm.question.inputFieldComponentName, {
                        tag: "component",
                        attrs: {
                          item: _vm.model.getSelectedAction(),
                          question: _vm.question,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    ref: "inputElement",
                    class: _vm.question.cssClasses.filterStringInput,
                    attrs: {
                      type: "text",
                      disabled: _vm.question.isInputReadOnly,
                      autocomplete: "off",
                      inputmode: _vm.model.inputMode,
                      role: _vm.model.filterStringEnabled
                        ? _vm.question.ariaRole
                        : null,
                      id: _vm.question.getInputId(),
                      tabindex: _vm.model.inputReadOnly ? undefined : -1,
                      readonly: !_vm.model.searchEnabled ? true : null,
                      "aria-label": _vm.question.placeholder,
                      "aria-expanded": _vm.question.ariaExpanded
                        ? "true"
                        : "false",
                      "aria-controls": _vm.model.listElementId,
                      "aria-activedescendant": _vm.model.ariaActivedescendant,
                      placeholder: _vm.model.placeholderRendered,
                    },
                    on: {
                      input: _vm.inputChange,
                      blur: _vm.blur,
                      focus: _vm.focus,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.question.allowClear &&
              _vm.question.cssClasses.cleanButtonIconId
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.question.isEmpty(),
                          expression: "!question.isEmpty()",
                        },
                      ],
                      class: _vm.question.cssClasses.cleanButton,
                      on: { click: _vm.clear },
                    },
                    [
                      _c("sv-svg-icon", {
                        class: _vm.question.cssClasses.cleanButtonSvg,
                        attrs: {
                          iconName: _vm.question.cssClasses.cleanButtonIconId,
                          title: _vm.question.clearCaption,
                          size: "auto",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.question.isReadOnly
        ? _c("sv-popup", {
            attrs: { model: _vm.question.dropdownListModel.popupModel },
          })
        : _c(
            "div",
            {
              class: _vm.question.getControlClass(),
              attrs: { disabled: "", id: _vm.question.inputId },
            },
            [
              _vm.question.selectedItemLocText
                ? _c("survey-string", {
                    attrs: { locString: _vm.question.selectedItemLocText },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.question.readOnlyText))]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }