








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { default as QuestionVue } from "./question";
import { Question } from "survey-core";
import { QuestionCustomModel } from "survey-core";

@Component
export class Custom extends QuestionVue<QuestionCustomModel> {
  get contentQuestion(): Question {
    return this.question.contentQuestion;
  }
  getComponentName(element: Question): string {
    if (element.customWidget) {
      return "survey-customwidget";
    }
    return "survey-" + element.getTemplate();
  }
}
Vue.component("survey-custom", Custom);

export default Custom;
