var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.question.getMaxLength()
    ? _c("input", {
        class: _vm.question.getControlClass(),
        style: _vm.inputStyle,
        attrs: {
          disabled: _vm.question.isInputReadOnly,
          type: _vm.question.inputType,
          maxlength: _vm.question.getMaxLength(),
          min: _vm.question.renderedMin,
          max: _vm.question.renderedMax,
          step: _vm.question.renderedStep,
          size: _vm.question.renderedInputSize,
          id: _vm.question.inputId,
          list: _vm.question.dataListId,
          placeholder: _vm.question.renderedPlaceholder,
          autocomplete: _vm.question.autocomplete,
          "aria-required": _vm.question.ariaRequired,
          "aria-label": _vm.question.ariaLabel,
          "aria-invalid": _vm.question.ariaInvalid,
          "aria-describedby": _vm.question.ariaDescribedBy,
        },
        domProps: { value: _vm.question.value },
        on: {
          change: _vm.question.onChange,
          keyup: _vm.question.onKeyUp,
          keydown: _vm.question.onKeyDown,
          "composition-update": _vm.question.onCompositionUpdate,
          blur: _vm.question.onBlur,
          focus: _vm.question.onFocus,
        },
      })
    : _c(
        "div",
        [
          _c("input", {
            class: _vm.question.getControlClass(),
            style: _vm.inputStyle,
            attrs: {
              disabled: _vm.question.isInputReadOnly,
              type: _vm.question.inputType,
              maxlength: _vm.question.getMaxLength(),
              min: _vm.question.renderedMin,
              max: _vm.question.renderedMax,
              step: _vm.question.renderedStep,
              size: _vm.question.renderedInputSize,
              id: _vm.question.inputId,
              list: _vm.question.dataListId,
              placeholder: _vm.question.renderedPlaceholder,
              autocomplete: _vm.question.autocomplete,
              "aria-required": _vm.question.ariaRequired,
              "aria-label": _vm.question.ariaLabel,
              "aria-invalid": _vm.question.ariaInvalid,
              "aria-describedby": _vm.question.ariaDescribedBy,
            },
            domProps: { value: _vm.question.value },
            on: {
              change: _vm.question.onChange,
              keyup: _vm.question.onKeyUp,
              keydown: _vm.question.onKeyDown,
              "composition-update": _vm.question.onCompositionUpdate,
              blur: _vm.question.onBlur,
              focus: _vm.question.onFocus,
            },
          }),
          _vm._v(" "),
          _c("sv-character-counter", {
            attrs: {
              counter: _vm.question.characterCounter,
              remainingCharacterCounter:
                _vm.question.cssClasses.remainingCharacterCounter,
            },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }