var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sv-vue-title-additional-div" },
    [
      _vm.element.isTitleRenderedAsString
        ? _c("survey-string", { attrs: { locString: _vm.element.locTitle } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString && _vm.element.isRequireTextOnStart
        ? _c(
            "span",
            {
              class: _vm.element.cssClasses.requiredText,
              attrs: { "aria-hidden": true },
            },
            [_vm._v(_vm._s(_vm.element.requiredText))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString && _vm.element.no
        ? _c(
            "span",
            {
              class:
                _vm.element.cssClasses.number ||
                _vm.element.cssClasses.panel.number,
              staticStyle: { position: "static" },
              attrs: { "aria-hidden": true },
            },
            [_vm._v(_vm._s(_vm.element.no))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString && _vm.element.no
        ? _c("span", [_vm._v(" ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString &&
      _vm.element.isRequireTextBeforeTitle
        ? _c(
            "span",
            {
              class: _vm.element.cssClasses.requiredText,
              attrs: { "aria-hidden": true },
            },
            [_vm._v(_vm._s(_vm.element.requiredText))]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString &&
      _vm.element.isRequireTextBeforeTitle
        ? _c("span", [_vm._v(" ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString
        ? _c("survey-string", { attrs: { locString: _vm.element.locTitle } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString &&
      _vm.element.isRequireTextAfterTitle
        ? _c("span", [_vm._v(" ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.element.isTitleRenderedAsString &&
      _vm.element.isRequireTextAfterTitle
        ? _c(
            "span",
            {
              class: _vm.element.cssClasses.requiredText,
              attrs: { "aria-hidden": true },
            },
            [_vm._v(_vm._s(_vm.element.requiredText))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }