var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.question.isReadOnlyRenderDiv() && !_vm.question.getMaxLength()
    ? _c("textarea", {
        class: _vm.question.className,
        style: { resize: _vm.question.resizeStyle },
        attrs: {
          readonly: _vm.question.isInputReadOnly,
          disabled: _vm.question.renderedInputDisabled,
          id: _vm.question.inputId,
          maxlength: _vm.question.getMaxLength(),
          cols: _vm.question.cols,
          rows: _vm.question.rows,
          placeholder: _vm.question.renderedPlaceholder,
          "aria-required": _vm.question.ariaRequired,
          "aria-label": _vm.question.ariaLabel,
          "aria-invalid": _vm.question.ariaInvalid,
          "aria-describedby": _vm.question.ariaDescribedBy,
        },
        domProps: { value: _vm.question.value },
        on: {
          change: _vm.change,
          input: function (e) {
            _vm.question.onInput(e)
          },
          keydown: function (e) {
            _vm.question.onKeyDown(e)
          },
        },
      })
    : !_vm.question.isReadOnlyRenderDiv() && _vm.question.getMaxLength()
    ? _c(
        "div",
        [
          _c("textarea", {
            class: _vm.question.className,
            style: { resize: _vm.question.resizeStyle },
            attrs: {
              readonly: _vm.question.isInputReadOnly,
              disabled: _vm.question.renderedInputDisabled,
              id: _vm.question.inputId,
              maxlength: _vm.question.getMaxLength(),
              cols: _vm.question.cols,
              rows: _vm.question.rows,
              placeholder: _vm.question.renderedPlaceholder,
              "aria-required": _vm.question.ariaRequired,
              "aria-label": _vm.question.ariaLabel,
              "aria-invalid": _vm.question.ariaInvalid,
              "aria-describedby": _vm.question.ariaDescribedBy,
            },
            domProps: { value: _vm.question.value },
            on: {
              change: _vm.change,
              input: function (e) {
                _vm.question.onInput(e)
              },
              keydown: function (e) {
                _vm.question.onKeyDown(e)
              },
            },
          }),
          _vm._v(" "),
          _c("sv-character-counter", {
            attrs: {
              counter: _vm.question.characterCounter,
              remainingCharacterCounter:
                _vm.question.cssClasses.remainingCharacterCounter,
            },
          }),
        ],
        1
      )
    : _c("div", [_vm._v(_vm._s(_vm.question.value))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }