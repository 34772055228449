














































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Base, SurveyModel, Question, SurveyElement, QuestionRowModel } from "survey-core";
import { BaseVue } from "./base";

@Component
export class SurveyElementVue extends BaseVue {
  @Prop() css: any;
  @Prop() survey: SurveyModel;
  @Prop() element: any;
  @Prop() row: QuestionRowModel;
  protected getModel(): Base {
    return this.element;
  }
    getComponentName(element: Question) {
    if (element.customWidget) return "survey-customwidget";
    if (element.getType() === "panel" || element.isDefaultRendering()) {
      return "survey-" + element.getTemplate();
    }
    return element.getComponentName();
  }
  getContentClass(element: Question) {
    return element.cssContent;
  }
  get hasErrorsOnTop() {
    return !this.element.isPanel && (<Question>this.element).showErrorOnTop;
  }
  get hasErrorsOnBottom() {
    return !this.element.isPanel && (<Question>this.element).showErrorOnBottom;
  }
  mounted() {
    if (!this.element.isPanel) {
      (<Question>this.element).afterRender(this.$el as HTMLElement);
    }
  }

}
Vue.component("survey-element", SurveyElementVue);
export default SurveyElementVue;
